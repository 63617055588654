export const dateToString = (date) => {
  // we need: YYYY-MM-DD hh:mm:ss
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ':' + seconds;

  return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
}

export const processDateForTable = (dateString) => {
  const NewDatetime = new Date(dateString);
  let date = `${NewDatetime.getDate() < 10 ? 
      '0' + NewDatetime.getDate() : 
      NewDatetime.getDate()}.` +
    `${NewDatetime.getMonth() < 9 ? 
        '0' + (NewDatetime.getMonth() + 1) : 
        NewDatetime.getMonth() + 1}.` +
    `${NewDatetime.getFullYear()}`;

  let time = `${NewDatetime.getHours() < 10 ? 
      '0' + NewDatetime.getHours() : 
      NewDatetime.getHours()}:` +
    `${NewDatetime.getMinutes() < 10 ? 
        '0' + NewDatetime.getMinutes() : 
        NewDatetime.getMinutes()}`

  return {'date': date, 'time': time};
}

export const dateFromString = (dateString) => {
  const newDate = new Date(dateString);
  return newDate;
}

export const splitDateString = (dateString) => {
  const NewDatetime = new Date(dateString);
    let date = `${NewDatetime.getFullYear()}-` +
    `${NewDatetime.getMonth() < 9 ? 
        '0' + (NewDatetime.getMonth() + 1) : 
        NewDatetime.getMonth() + 1}-` +
    `${NewDatetime.getDate() < 10 ? 
        '0' + NewDatetime.getDate() : 
        NewDatetime.getDate()}`;
  let time = `${NewDatetime.getHours() < 10 ? 
      '0' + NewDatetime.getHours() : 
      NewDatetime.getHours()}:` +
    `${NewDatetime.getMinutes() < 10 ? 
        '0' + NewDatetime.getMinutes() : 
        NewDatetime.getMinutes()}`
  return {'date': date, 'time': time};
}
