import { useState } from "react";
import { FaFilter } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

// Components
import MyButton from "./MyButton";

import { deps, buildings, rooms_102, rooms_45 } from "../config";

const Filters = ({ eventQuery, setEventQuery, onSearchClick }) => {
  const resetQuery = () => {
    setEventQuery({
      fromDatetime: "",
      toDatetime: "",
      id: "",
      department: "",
      class: "",
      teacher: "",
      building: "",
      room: "",
    });
  };

  const submit = (e) => {
    e.preventDefault();

    onSearchClick();
  };

  return (
    <>
      <form className="filter-form" id="filter-form" onSubmit={submit}>
        <div className="input-control from-filter">
          <div>
            <label>Von</label>
          </div>
          <input
            type="date"
            value={eventQuery.fromDatetime}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, fromDatetime: e.target.value })
            }
          />
        </div>
        <div className="input-control to-filter">
          <div>
            <label>Bis</label>
          </div>
          <input
            type="date"
            value={eventQuery.toDatetime}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, toDatetime: e.target.value })
            }
          />
        </div>

        <div className="input-control department-filter">
          <div>
            <label>Bereich</label>
          </div>
          <select
            value={eventQuery.department}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, department: e.target.value })
            }
          >
            {deps.map((dep) => (
              <option key={dep} value={dep}>
                {dep}
              </option>
            ))}
          </select>
        </div>
        <div className="input-control building-filter">
          <div>
            <label>Gebäude</label>
          </div>
          <select
            value={eventQuery.building}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, building: e.target.value })
            }
          >
            {buildings.map((building) => (
              <option key={building} value={building}>
                {building}
              </option>
            ))}
          </select>
        </div>
        <div className="input-control room-filter">
          <div>
            <label>Raum</label>
          </div>
          {eventQuery.building === "Lagerstrasse 45" ? (
            <select
              onChange={(e) =>
                setEventQuery({ ...eventQuery, room: e.target.value })
              }
            >
              {rooms_45.map((room) => (
                <option key={room} value={room}>
                  {room}
                </option>
              ))}
            </select>
          ) : eventQuery.building === "Lagerstrasse 102" ? (
            <select
              onChange={(e) =>
                setEventQuery({ ...eventQuery, room: e.target.value })
              }
            >
              {rooms_102.map((room, key) => (
                <option key={key} value={room}>
                  {room}
                </option>
              ))}
            </select>
          ) : (
            <select
              onChange={(e) =>
                setEventQuery({ ...eventQuery, room: e.target.value })
              }
            >
              <option value=""></option>
              <option value="Online">Online</option>
            </select>
          )}
        </div>

        <div className="input-control id-filter">
          <div>
            <label>ID</label>
          </div>
          <input
            type="text"
            value={eventQuery.id}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, id: e.target.value })
            }
          />
        </div>
        <div className="input-control class-filter">
          <div>
            <label>Klasse</label>
          </div>
          <input
            type="text"
            value={eventQuery.class}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, class: e.target.value })
            }
          />
        </div>
        <div className="input-control teacher-filter">
          <div>
            <label>Dozent</label>
          </div>
          <input
            type="text"
            value={eventQuery.teacher}
            onChange={(e) =>
              setEventQuery({ ...eventQuery, teacher: e.target.value })
            }
          />
        </div>
        <div className="reset-button">
          <MyButton text="Reset" onClick={resetQuery} />
        </div>
      </form>
    </>
  );
};

const TableNav = ({
  getEvents,
  setPage,
  eventQuery,
  setEventQuery,
  genericSearch,
  setGenericSearch,
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);

  const onFilterClick = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onSearchClick = () => {
    setPage(0);
    getEvents();
  };

  const submit = (e) => {
    e.preventDefault();

    onSearchClick();
  };

  return (
    <div className="table-nav-container">
      <form id="generic-search" onSubmit={submit}>
        <div className="table-nav-child">
          <label>Suche</label>
        </div>

        <div className="table-nav-input">
          <input
            type="text"
            value={genericSearch}
            onChange={(e) => setGenericSearch(e.target.value)}
          />
        </div>

        <div className="table-nav-buttons">
          <div className={`filter-button `}>
            <MyButton
              text=""
              active={filtersVisible}
              onClick={onFilterClick}
              renderIcon={() => <FaFilter size={32} />}
            />
          </div>

          <div className="search-button">
            <MyButton
              text="Suchen"
              onClick={() => {
                if (!filtersVisible) onSearchClick();
              }}
              form="filter-form"
              type="submit"
            />
          </div>
        </div>
      </form>

      <AnimatePresence initial={false}>
        {filtersVisible && (
          <motion.div
            className="filter-container"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Filters
              eventQuery={eventQuery}
              setEventQuery={setEventQuery}
              onSearchClick={onSearchClick}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TableNav;
