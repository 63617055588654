export const config = {
  url: "https://weevil-premium-parakeet.ngrok-free.app/api/v1/",
  //url: 'http://localhost:5001/api/v1/',
  eventsPerPage: 50,
};
export const deps = ["", "JWI", "JMA", "JME", "JTE", "SJS", "WSAG"];
export const buildings = ["", "Lagerstrasse 102", "Lagerstrasse 45"];
export const rooms_102 = [
  "",
  "201",
  "203",
  "204a",
  "204b",
  "205a",
  "205b",
  "206",
  "207a",
  "207b",
  "301",
  "303",
  "304",
  "305",
  "306",
  "307a",
  "307b",
  "401",
  "402",
  "403",
  "404a",
  "404b",
  "405a",
  "405b",
  "406",
  "407",
  "408",
];
export const rooms_45 = [
  "",
  "ZL O1.01",
  "ZL O1.03",
  "ZL O1.05",
  "ZL O1.06",
  "ZL O1.08",
  "ZL O1.10",
  "ZL O3.05",
  "ZL O3.08",
  "ZL O4.01",
  "ZL O4.03",
  "ZL O4.05",
  "ZL O4.06",
  "ZL O4.08",
  "ZL O4.10",
  "ZL O4.15",
  "ZL O4.12",
  "ZL O4.14",
  "ZL O4.18",
];
