import {useState} from 'react';

// Components
import MyButton from './MyButton';

const LoginBox = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({
    username: '',
    userpw: '',
  });

  const submit = (e) => {
    e.preventDefault();

    onLogin(credentials);
  }

  return (
    <>
      <div className='hover-box login-box-container'>
        <form onSubmit={submit} id='login-form'>
          <div className='input-control login'>
            <div>
            <label>Username</label>
            </div>
          <input type='text' value={credentials.username} 
            onChange={(e) => setCredentials({...credentials, username: e.target.value})}
          />
          </div>

          <div className='input-control login'>
            <div>
            <label>Password</label>
            </div>
          <input type='password' value={credentials.userpw} 
            onChange={(e) => setCredentials({...credentials, userpw: e.target.value})}
          />
          </div>
          <MyButton 
            text='Login'
            onClick={submit}
            type='submit'
            form='login-form'
          />
        </form>
      </div>
    </>
  )
}

export default LoginBox;
