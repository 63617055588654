import MyButton from './MyButton';

const ConfirmPopUp = ({ message, onConfirm, onCancel }) => {
  return (
    <div className={`hover-box confirm-container`} >
      <h4>{message}</h4>
      <div className='buttons'>
        <MyButton
          text='Abbrechen'
          onClick={onCancel}
        />
        <MyButton
          text='Annehmen'
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

ConfirmPopUp.defaultProps = {
  message: 'PlaceHolder message',
  onConfirm: () => {},
  onCancel: () => {},
}

export default ConfirmPopUp;
