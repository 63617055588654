import { useState, useRef } from 'react';
import { FaTimes, FaEdit, FaEye, FaEyeSlash, FaAngleRight, FaAngleDoubleRight, FaAngleLeft, FaAngleDoubleLeft} from 'react-icons/fa';

import { processDateForTable, dateToString } from '../helper';

// Components
import MyButton from './MyButton';
import ConfirmPopUp from './ConfirmPopUp';
import EditEvent from './EditEvent';

const Table = ({ 
  events, nextPageClick, prevPageClick, firstPageClick, lastPageClick, page, computeNumberOfPages, 
  eventToEdit, updateEvent, deleteEvent, isAdmin
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const confirmMessage = useRef('');

  const tableHeaders = [ 'ID', 'Datum', 'Von', 'Bis', 'Bereich', 'Klasse', 'Dozent', 'Fach', 'Gebäude', 'Zimmer', '' ];

  const onHideEvent = (event) => {
    const newEvent = {...event, 
      visible: !event.visible,
      fromDatetime: dateToString(new Date(event.fromDatetime)),
      toDatetime: dateToString(new Date(event.toDatetime)),
    };
    eventToEdit.current = newEvent;
    console.log(newEvent);
    console.log(event);

    updateEvent();
  }

  const cancelDelete = () => {
    setConfirmVisible(false);
  }

  const confirmDelete = () => {
    deleteEvent();
    setConfirmVisible(false);
  }

  const onDeleteEvent = (event) => {
    eventToEdit.current = event;
    confirmMessage.current = `Soll das Ereignis mit ID ${event.id} wirklich unwiederruflich gelöscht werden?`

    setConfirmVisible(true);
  }

  const onEditEvent = (event) => {
    eventToEdit.current = event;
    setEditVisible(true);
  }

  const cancelEdit = () => {
    setEditVisible(false);
  }

  return (
    <>
      { confirmVisible && (
        <>
          <div className='background confirm'>
          </div>
          <ConfirmPopUp
            message={confirmMessage.current}
            onCancel={cancelDelete}
            onConfirm={confirmDelete}
          />
        </>
      ) }

      { editVisible && (
        <>
          <div className='background'>
          </div>
          <EditEvent
            eventToEdit={eventToEdit}
            onCancelEdit={cancelEdit}
            updateEvent={updateEvent}
          />
        </>
      ) }

      <div className='table-container'>
        <div className='table-page-navigator'>
          <div className='table-page-buttons'>
            <MyButton
              text=''
              customClass='round page'
              onClick={firstPageClick}
              renderIcon={() =>  <FaAngleDoubleLeft size={40}
                style={{margin: '0rem 0.1rem 0 0'}}
              /> }
            />
            <MyButton
              text=''
              customClass='round page'
              onClick={prevPageClick}
              renderIcon={() =>  <FaAngleLeft size={40}
                style={{margin: '0rem 0.1rem 0 0'}}
              /> }
            />
          </div>
          <div>
            <h2>{page + 1}/{computeNumberOfPages()}</h2>
          </div>
          <div className='table-page-buttons'>
            <MyButton
              text=''
              customClass='round page'
              onClick={nextPageClick}
              renderIcon={() =>  <FaAngleRight size={40} 
                style={{margin: '0rem -0.1rem 0 0'}}
              /> }
            />
            <MyButton
              text=''
              customClass='round page'
              onClick={lastPageClick}
              renderIcon={() =>  <FaAngleDoubleRight size={40} 
                style={{margin: '0rem -0.1rem 0 0'}}
              /> }
            />
          </div>
        </div>
      </div>

        <div className='table-body'>
          { tableHeaders.map((key) => {
            return (
              <div key={key} className='table-head'>
                <h2>{key}</h2>
              </div>
            )
          })
          }
          {
            events.map((event) => {
              const fields = [ 
                event.id, processDateForTable(event.fromDatetime).date,
                processDateForTable(event.fromDatetime).time, 
                processDateForTable(event.toDatetime).time,
                event.department, event.className, event.teacher, event.subject, 
                event.building, event.room
              ]
              return (
                <>
                  {
                    fields.map((entry, index) => {
                      return(
                        <div key={event.id + index}
                          className={`table-entry ${event.visible ? '' : 'hidden'}`}>
                          <p>{entry}</p>
                        </div>
                      )
                    })
                  }
                  <div key={event.id + 'icon'}
                    className={ `table-entry icons ${event.visible ? '' : 'hidden'}` }>
                    { event.visible ? 
                      <>
                        <button className='blank-button' onClick={() => onHideEvent(event)} >
                          <FaEyeSlash className='eye' size={20} /> 
                        </button>
                      </> :
                      <>
                        <button className='blank-button' onClick={() => onHideEvent(event)} >
                          <FaEye className='eye' size={20} /> 
                        </button>
                      </>
                    }
                    <button className='blank-button' onClick={() => onEditEvent(event)}>
                      <FaEdit className='edit' size={20} />
                    </button>
                    { isAdmin ?
                      <button className='blank-button' onClick={() => onDeleteEvent(event)}>
                        <FaTimes className='delete' size={22} />
                      </button> :
                        null
                    }
                  </div>
                </>
              )
            })
          }
        </div>

      <div className='table-page-navigator'>
        <div className='table-page-buttons'>
          <MyButton
            text=''
            customClass='round page'
            onClick={firstPageClick}
            renderIcon={() =>  <FaAngleDoubleLeft size={40}
              style={{margin: '0rem 0.1rem 0 0'}}
            /> }
          />
          <MyButton
            text=''
            customClass='round page'
            onClick={prevPageClick}
            renderIcon={() =>  <FaAngleLeft size={40}
              style={{margin: '0rem 0.1rem 0 0'}}
            /> }
          />
        </div>
        <div>
          <h2>{page + 1}/{computeNumberOfPages()}</h2>
        </div>
        <div className='table-page-buttons'>
          <MyButton
            text=''
            customClass='round page'
            onClick={nextPageClick}
            renderIcon={() =>  <FaAngleRight size={40} 
              style={{margin: '0rem -0.1rem 0 0'}}
            /> }
          />
          <MyButton
            text=''
            customClass='round page'
            onClick={lastPageClick}
            renderIcon={() =>  <FaAngleDoubleRight size={40} 
              style={{margin: '0rem -0.1rem 0 0'}}
            /> }
          />
        </div>
      </div>
    </>
  )
}

export default Table;
