import {useState, useEffect, useRef} from 'react';
import { FaAngleUp, FaPlus } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import MyButton from './components/MyButton';
import MyHeader from './components/MyHeader';
import TableNav from './components/TableNav';
import Table from './components/Table';
import LoginBox from './components/LoginBox';
import AddEvent from './components/AddEvent';
import UserManager from './components/UserManager';
import Logs from './components/Logs';
import Toast from './components/Toast';

// Config
import { config } from './config';

function App() {
  // States
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [events, setEvents] = useState([]);
  const [ logs, setLogs ] = useState({});
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [eventQuery, setEventQuery] = useState({
    fromDatetime: '',
    toDatetime: '',
    id: '',
    department: '',
    className: '',
    teacher: '',
    building: '',
    room: '',
  })
  const [genericSearch, setGenericSearch] = useState('');

  const [addEventVisible, setAddEventvisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);

  const eventToEdit = useRef();
  const header = useRef({
    'Content-Type': 'application/json',
    User: sessionStorage.getItem('user') === null ? '' : sessionStorage.getItem('user'),
    Authorization: sessionStorage.getItem('token') === null ? '' : sessionStorage.getItem('token'),
  });
  const toastMessage = useRef('');

  // Timer
  useEffect(() => {
    const id = setInterval(() => {
      getEvents();
      getLogs();
      checkToken();
    }, 20000);

    return () => clearInterval(id);
  })

  // On load
  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  // On login status change
  useEffect(() => {
    getEvents();
    getLogs();
    // eslint-disable-next-line
  }, [loggedIn]);

  // On search change
  useEffect(() => {
    updateGenericSearch();
    setPage(0);
    // eslint-disable-next-line
  }, [genericSearch]);

  // On event change
  useEffect(() => {
    updateGenericSearch();
    // eslint-disable-next-line
  }, [events]);

  // Functions
  const updateGenericSearch = () => {
    if (genericSearch !== '') {
      const searchQuery = genericSearch.toLowerCase();
      const tempFilteredEvents = events.filter(({ id, department, subject, teacher, className, building, room }) => 
        (id + department + subject + teacher + className + building + room).toLowerCase().includes(searchQuery)
      );
      setFilteredEvents(tempFilteredEvents);
    } else {
      setFilteredEvents(events);
    }
  }

  const onLogoutClick = () => {
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('user', '');
    setIsAdmin(false);

    header.current.User = '';
    header.current.Authorization = '';

    setLoggedIn(false);
  } 

  const onCancelAdd = () => {
    setAddEventvisible(false);
  }

  const computeNumberOfPages = () => {
    var numberOfPages = filteredEvents.length % config.eventsPerPage ?
      Math.floor(filteredEvents.length / config.eventsPerPage) + 1 : 
      Math.floor(filteredEvents.length / config.eventsPerPage);
    if (Object.is(numberOfPages, NaN)) return 0;
    else return numberOfPages;
  };

  const prevPageClick = () => {
    if (page > 0) setPage(page - 1);
  };

  const nextPageClick = () => {
    if (page < computeNumberOfPages() - 1) setPage(page + 1);
  };

  const lastPageClick = () => {
    setPage(computeNumberOfPages() - 1);
  };

  const firstPageClick = () => {
    setPage(0);
  };

  // API Interaction
  const checkToken = async () => {
    try {
      const response = await fetch(config.url + 'user/check', {
        method: 'GET',
        headers: header.current,
      });

      const responseData = await response.json();
      console.log(responseData);

      if (response.status === 200) {
        setLoggedIn(true);
        if (responseData.isAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setLoggedIn(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onLogin = async (credentials) => { 
    try {
      const response = await fetch(config.url + 'user/', {
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: header.current,
      });

      if (response.status !== 200) {
        throw Error('Benutzername oder Passwort ungültig.');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoggedIn(true);
      sessionStorage.setItem('token', responseData.token);
      sessionStorage.setItem('user', credentials.username);
      setIsAdmin(responseData.isAdmin);

      header.current.User = credentials.username;
      header.current.Authorization = responseData.token;
    } catch (e) {
      alert(e.message);
    }
  }

  const getLogs = async () => {
    if (isAdmin) {
      try {
        const response = await fetch(config.url + 'logs/', {
          method: 'GET',
          headers: header.current,
        })

        if (response.status !== 200) {
          throw Error('Something didn\'t work.', response);
        }

        const responseData = await response.json();
        setLogs(responseData);
      } catch (e) {
        console.log(e.message)
      }
    } else {
      setLogs([]);
    }
  }

  const getEvents = async () => {
    var queryString = '';
    for (const [key, value] of Object.entries(eventQuery)) {
      if (value)
        if (queryString)
          queryString += `&${key}=${value}`
        else
          queryString += `?${key}=${value}`
    }

    if (loggedIn) { 
      try {
        const response = await fetch (config.url + 'event/' + queryString, {
          method: 'GET',
          headers: header.current,
        });

        console.log(response);

        if (response.status !== 200) {
          throw Error('Something didn\'t work.', response);
        }

        let responseData = await response.json();
        responseData.forEach((event, index) => {
          responseData[index].fromDatetime = responseData[index].fromDatetime.replace('T', ' ').replace('Z', '');
          responseData[index].toDatetime = responseData[index].toDatetime.replace('T', ' ').replace('Z', '');
        })

        setEvents(responseData);
      } catch (e) {
        console.log(e.message)
      }
    } else {
      setEvents([]);
      setGenericSearch('');
    }
  }

  const updateEvent = async () => {
    try {
      const response = await fetch(config.url + 'event/' + eventToEdit.current.id, {
        method: 'PUT',
        body: JSON.stringify(eventToEdit.current),
        headers: header.current,
      })

      if (response.status !== 200) {
        throw Error("Couldn't update event")
      }

      console.log(response);
      toastMessage.current = "Ereignis erfolgreich angepasst";
      setToastVisible(true);

      getEvents();
    } catch (e) {
      console.log(e.message);
      toastMessage.current = "Ereignis konnte nicht angepasst werden!";
      setToastVisible(true);
    }
  }

  const deleteEvent = async () => {
    try {
      const response = await fetch(config.url + 'event/' + eventToEdit.current.id, {
        method: 'DELETE',
        headers: header.current,
      })

      if (response.status !== 200) {
        throw Error("Couldn't delete event");
      }

      console.log(response);
      toastMessage.current = "Ereignis erfolgreich gelöscht";
      setToastVisible(true);

      getEvents();
    } catch (e) {
      console.log(e.message);
      toastMessage.current = "Ereigniss konnte nicht gelöscht werden!";
      setToastVisible(true);
    }
  }

  const addEvent = async () => {
    try {
      const newEvent = eventToEdit.current;
      delete newEvent.id;
      const response = await fetch(config.url + 'event/', {
        method: 'POST',
        body: JSON.stringify(newEvent),
        headers: header.current,
      })
      console.log(newEvent);

      if (response.status !== 200) {
        throw Error("Couldn't add event");
      }

      console.log(response);
      toastMessage.current = "Ereigniss erfolgreich hinzugefügt";
      setToastVisible(true);

      getEvents();
    } catch (e) {
      console.log(e.message);
      toastMessage.current = "Ereigniss konnte nicht hinzugefügt werden!";
      setToastVisible(true);
    }
  }

  return (
    <div className='container'>

      <Toast
        message={toastMessage.current}
        toastVisible={toastVisible}
        setToastVisible={setToastVisible}
      />

      <AnimatePresence initial={false}>
        { !loggedIn && (
          <motion.div
            key='content'
            initial='transparent'
            animate='visible'
            exit='transparent'
            variants={{
              transparent: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className='background confirm'>
            </div>
            <LoginBox onLogin={onLogin} />
          </motion.div>
        )}
      </AnimatePresence>

      <UserManager
        header={header}
        onLogoutClick={onLogoutClick}
        isAdmin={isAdmin}
      />

      { isAdmin && (
            <Logs
              logs={logs}
              getLogs={getLogs}
            />
      )}

      { addEventVisible && (
        <>
          <div className='background'>
          </div>
          <AddEvent
            eventToEdit={eventToEdit}
            onCancelAdd={onCancelAdd}
            addEvent={addEvent}
          />
        </>
      )}

      <MyHeader 
        onLogoutClick={onLogoutClick}
        loggedIn={loggedIn}
      />

      <TableNav
        eventQuery={eventQuery}
        setPage={setPage}
        setEventQuery={setEventQuery}
        getEvents={getEvents}
        genericSearch={genericSearch}
        setGenericSearch={setGenericSearch}
      />

      <Table 
        events={filteredEvents.length ? filteredEvents.slice(page * config.eventsPerPage, (page + 1) * config.eventsPerPage) : filteredEvents}
        nextPageClick={nextPageClick}
        prevPageClick={prevPageClick}
        firstPageClick={firstPageClick}
        lastPageClick={lastPageClick}
        page={page}
        computeNumberOfPages={computeNumberOfPages}
        eventToEdit={eventToEdit}
        updateEvent={updateEvent}
        deleteEvent={deleteEvent}
        isAdmin={isAdmin}
      />

      <MyButton
        customClass='round to-top'
        text=''
        onClick={() => {window.scrollTo(0, 0)}}
        renderIcon={() => <FaAngleUp size={38} />}
      />
      <MyButton
        customClass='round create-new'
        text=''
        onClick={() => setAddEventvisible(true)}
        renderIcon={() => <FaPlus size={30} />}
      />

    </div>
  );
}

export default App;
