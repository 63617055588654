import { useEffect } from 'react';

const Toast = ({ message, toastVisible, setToastVisible }) => {

  useEffect(() =>
    {
      if (toastVisible)
        setTimeout(() => setToastVisible(false), 3000)

    }, [toastVisible, setToastVisible])
  
  if (!toastVisible) {
    return null;
  } else {
    return (
      <div className='hover-box toast'>
        <div>
          <p>{message}</p>
        </div>
      </div>
    )
  }
}

export default Toast;
