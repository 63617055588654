import PropTypes from 'prop-types';

const MyButton = ({ text, onClick, renderIcon, active, customClass, form, type }) => {
  return (
    <div className={`juv-button ${active ? 'activated' : ''} ${customClass}`} onClick={onClick} >
      <button 
        form={form}
        type={type}
      >
        { text.length > 0 ?
          <p>{text}</p> : null
        }
        {renderIcon()}
      </button>
    </div>
  )
}

MyButton.defaultProps = {
  text: 'Button',
  onClick: () => console.log('click'),
  renderIcon: () => null, 
  active: false,
  customClass: '',
  form: '',
  type: '',
}

MyButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  renderIcon: PropTypes.func,
  active: PropTypes.bool,
  customClass: PropTypes.string,
  form: PropTypes.string,
  type: PropTypes.string,
}

export default MyButton;
