import { useState } from 'react';

// Components
import MyButton from './MyButton';

const LogTable = ({ data }) => {
  return (
    <div className='log-table-data-container'>
      <div className='log-table-data'>
        <div className='log-table-head'>
          <p>Time stamp</p>
        </div>
        <div className='log-table-head'>
          <p>Type</p>
        </div>
        <div className='log-table-head'>
          <p>Message</p>
        </div>

        { data.map(logEntry => ( 
          <>
            <div className='log-table-entry'>
              <p>{logEntry.timestamp}</p>
            </div>
            <div className='log-table-entry'>
              <p>{logEntry.type}</p>
            </div>
            <div className='log-table-entry'>
              <p>{logEntry.message}</p>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

const Logs = ({ logs, getLogs }) => {
  const [ logsVisible, setLogsVisible ] = useState(false);


  return (
    <div className='logs'>
      { logsVisible ? 
        <div className='hover-box log-table'>
          <h2>Logs</h2>
          <LogTable 
            data={logs}
          />
          <div className='button-div'>
            <MyButton
              text='Close'
              onClick={() =>{
                setLogsVisible(false);
              }}
            />
          </div>
        </div> :

        <div className='hover-box log-button'
          style={{
            'transform': 'translate(-69%) rotate(-90deg)',
            'height': '3rem', 'top': '85%',
          }}>
          <div className='button-container'>
            <MyButton
              text='Logs'
              onClick={() =>{
                setLogsVisible(true);
                getLogs();
              }}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default Logs;
