import { useState, useRef, useEffect } from "react";
import { FaAngleDown, FaAngleUp, FaAngleRight, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

import { config } from "../config";

// Components
import MyButton from "./MyButton";
import ConfirmPopUp from "./ConfirmPopUp";
import Toast from "./Toast";

const UserManager = ({ header, onLogoutClick, isAdmin }) => {
  const [userManagerVisible, setUserManagerVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    userpw: "",
  });
  const [userpwToChange, setUserpwToChange] = useState({
    username: "",
    userpwold: "",
    userpwnew: "",
    userpwnewconf: "",
  });

  const confirmFunc = useRef(() => {});
  const cancelFunc = useRef(() => {});
  const confirmMsg = useRef("");
  const toastMessage = useRef("");

  const userToDelete = useRef("");
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const onCreateNewUser = (e) => {
    e.preventDefault();

    confirmFunc.current = onConfirmAdd;
    cancelFunc.current = onCancelAdd;
    confirmMsg.current = `Soll der Benutzer ${newUser.username} wirklich erstellt werden?`;
    setConfirmVisible(true);
  };

  const onCancelAdd = () => {
    setConfirmVisible(false);
  };

  const onConfirmAdd = () => {
    createUser();
    setConfirmVisible(false);
    getUsers();
  };

  const onDeleteUser = (username) => {
    userToDelete.current = username;
    confirmFunc.current = confirmDelete;
    cancelFunc.current = cancelDelete;
    confirmMsg.current = `Soll der Benutzer ${username} wirklich gelöscht werden?`;
    setConfirmVisible(true);
  };

  const confirmDelete = () => {
    deleteUser();
    setConfirmVisible(false);
    getUsers();
  };

  const cancelDelete = () => {
    setConfirmVisible(false);
  };

  const onUserChangepw = (e) => {
    e.preventDefault();

    if (userpwToChange.username === header.current.User) {
      if (userpwToChange.userpwnew !== userpwToChange.userpwnewconf) {
        alert("Die neuen Passwörter müssen identisch sein!");
        setUserpwToChange({
          ...userpwToChange,
          userpwnew: "",
          userpwnewconf: "",
        });
      } else {
        confirmFunc.current = confirmChange;
        cancelFunc.current = cancelChange;
        confirmMsg.current = `Soll das Passwort wirklich geändert werden?`;

        setConfirmVisible(true);
      }
    } else {
      if (userpwToChange.userpwnew !== userpwToChange.userpwnewconf) {
        alert("Die Eingaben müssen identisch sein!");
        setUserpwToChange({
          ...userpwToChange,
          userpwnew: "",
          userpwnewconf: "",
        });
      } else {
        confirmFunc.current = confirmChange;
        cancelFunc.current = cancelChange;
        confirmMsg.current = `Soll das Passwort von Benutzer ${userpwToChange.username} wirklich geändert werden?`;
        setConfirmVisible(true);
      }
    }
  };

  const confirmChange = () => {
    updatePassword();
    setConfirmVisible(false);
    if (userpwToChange.username === header.current.User) {
      onLogoutClick();
    }
    setUserpwToChange({
      ...userpwToChange,
      username: "",
      userpwold: "",
      userpwnew: "",
      userpwnewconf: "",
    });
  };

  const cancelChange = () => {
    setUserpwToChange({
      ...userpwToChange,
      username: "",
      userpwold: "",
      userpwnew: "",
      userpwnewconf: "",
    });
    setConfirmVisible(false);
  };

  // API calls
  const getUsers = async () => {
    if (isAdmin) {
      try {
        const response = await fetch(config.url + "user", {
          method: "GET",
          headers: header.current,
        });

        if (response.status !== 200) {
          throw Error("Coulnd't get users");
        }

        const responseData = await response.json();

        setUsers(responseData);
      } catch (e) {
        console.log(e.message);
      }
    } else {
      setUsers([]);
    }
  };

  const createUser = async () => {
    try {
      const response = await fetch(config.url + "user/create", {
        method: "POST",
        body: JSON.stringify(newUser),
        headers: header.current,
      });

      if (response.status === 204) {
        alert("Dieser Benuzter existiert bereits");
      } else if (response.status !== 200) {
        throw Error("Coulnd't create user");
      }

      console.log(response);
      toastMessage.current = "Benuzter erfolgreich hinzugefügt";
      setToastVisible(true);
    } catch (e) {
      console.log(e.message);
      toastMessage.current = "Benuzter konnte nicht hinzugefügt werden!";
      setToastVisible(true);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await fetch(config.url + "user", {
        method: "DELETE",
        body: JSON.stringify({ username: userToDelete.current }),
        headers: header.current,
      });

      if (response.status !== 200) {
        throw Error("Couldn't delete user");
      }

      console.log(response);
      toastMessage.current = "Benuzter erfolgreich gelöscht";
      setToastVisible(true);

      getUsers();
    } catch (e) {
      console.log(e);
      toastMessage.current = "Benuzter konnte nicht gelöscht werden!";
      setToastVisible(true);
    }
  };

  const updatePassword = async () => {
    try {
      const response = await fetch(config.url + "user/update", {
        method: "PUT",
        body: JSON.stringify({
          username: userpwToChange.username,
          userpwold: userpwToChange.userpwold,
          userpwnew: userpwToChange.userpwnew,
        }),
        headers: header.current,
      });

      if (response.status !== 200) {
        throw Error("Couldn't update Password");
      }

      console.log(response);
      toastMessage.current = "Benuzter Passwort erfolgreich geändert";
      setToastVisible(true);
    } catch (e) {
      console.log(e.message);
      alert("Ihr Passwort ist nicht korrekt!");
      toastMessage.current = "Benuzter Passwort konnte nicht geändert werden!";
      setToastVisible(true);
    }
  };

  return (
    <>
      <Toast
        message={toastMessage.current}
        toastVisible={toastVisible}
        setToastVisible={setToastVisible}
      />

      {confirmVisible && (
        <>
          <div className="background confirm"></div>
          <ConfirmPopUp
            message={confirmMsg.current}
            onCancel={cancelFunc.current}
            onConfirm={confirmFunc.current}
          />
        </>
      )}

      <div
        className="hover-box user-manager-container"
        style={
          userManagerVisible
            ? {
                transform: "translate(-100%)",
                height: "auto",
                top: "10%",
              }
            : {
                transform: "translate(-56%) rotate(-90deg)",
                height: "3rem",
                top: "30%",
              }
        }
      >
        <div className="top-banner">
          <MyButton
            text="User Manager"
            onClick={() => {
              getUsers();
              setUserManagerVisible(!userManagerVisible);
            }}
            renderIcon={() => {
              if (userManagerVisible)
                return (
                  <FaTimes size={30} style={{ margin: "0 2rem 0 0.5rem" }} />
                );
              else
                return (
                  <FaAngleUp size={30} style={{ margin: "0 2rem 0 0.5rem" }} />
                );
            }}
            customClass={`banner-button ${userManagerVisible ? "open" : ""}`}
          />
        </div>

        <div className="content">
          <h2>Angemeldeter Benutzer</h2>
          <div className="current-user">
            <form
              id="change-ownpw-form"
              className="user"
              onSubmit={onUserChangepw}
            >
              <div>
                <p>{header.current.User}</p>
              </div>
              <MyButton
                text={
                  header.current.User === userpwToChange.username
                    ? "Abbrechen"
                    : "PW ändern"
                }
                onClick={() => {
                  if (header.current.User === userpwToChange.username) {
                    setUserpwToChange({
                      ...userpwToChange,
                      username: "",
                      userpwold: "",
                      userpwnew: "",
                      userpwnewconf: "",
                    });
                  } else
                    setUserpwToChange({
                      ...userpwToChange,
                      username: header.current.User,
                    });
                }}
                customClass="small"
              />
              {header.current.User === userpwToChange.username ? (
                <MyButton
                  text="Annehmen"
                  onClick={() => {}}
                  form="change-ownpw-form"
                  customClass="small"
                />
              ) : (
                <div></div>
              )}

              {header.current.User === userpwToChange.username && (
                <>
                  <div className="label-box">
                    <p className="label">Altes Passwort</p>
                  </div>
                  <div className="input-control change-pw">
                    <input
                      type="password"
                      value={userpwToChange.userpwold}
                      required
                      onChange={(e) =>
                        setUserpwToChange({
                          ...userpwToChange,
                          userpwold: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="label-box">
                    <p className="label">Neues Passwort</p>
                  </div>
                  <div className="input-control change-pw">
                    <input
                      type="password"
                      value={userpwToChange.userpwnew}
                      required
                      pattern="^(?=.*\d).{8,}$"
                      title="Min. 8 Zeichen und 1 Zahl."
                      onChange={(e) =>
                        setUserpwToChange({
                          ...userpwToChange,
                          userpwnew: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="label-box">
                    <p className="label" style={{ marginBottom: "2rem" }}>
                      Passwort bestätigen
                    </p>
                  </div>
                  <div
                    className="input-control change-pw"
                    style={{ marginBottom: "2rem" }}
                  >
                    <input
                      type="password"
                      value={userpwToChange.userpwnewconf}
                      required
                      pattern="^(?=.*\d).{8,}$"
                      title="Min. 8 Zeichen und 1 Zahl."
                      onChange={(e) =>
                        setUserpwToChange({
                          ...userpwToChange,
                          userpwnewconf: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </form>
          </div>

          {isAdmin && users.length > 0 && (
            <>
              <h2>Andere Benutzer</h2>
              <div className="other-users">
                <form
                  id="change-pw-form"
                  className="user"
                  onSubmit={onUserChangepw}
                >
                  {users.map((user) => (
                    <>
                      <div>
                        <p>{user.username}</p>
                      </div>
                      <MyButton
                        text={
                          user.username === userpwToChange.username
                            ? "Abbrechen"
                            : "PW ändern"
                        }
                        onClick={() => {
                          if (user.username !== userpwToChange.username) {
                            setUserpwToChange({
                              ...userpwToChange,
                              username: user.username,
                            });
                          } else {
                            setUserpwToChange({
                              ...userpwToChange,
                              username: "",
                              userpwold: "",
                              userpwnew: "",
                              userpwnewconf: "",
                            });
                          }
                        }}
                        customClass="small"
                      />
                      <MyButton
                        text={
                          user.username === userpwToChange.username
                            ? "Annehmen"
                            : "Löschen"
                        }
                        onClick={() => {
                          if (user.username !== userpwToChange.username)
                            onDeleteUser(user.username);
                        }}
                        form={
                          user.username === userpwToChange.username
                            ? "change-pw-form"
                            : ""
                        }
                        customClass="small"
                      />
                      {user.username === userpwToChange.username && (
                        <>
                          <div className="label-box">
                            <p className="label">Neues Passwort</p>
                          </div>
                          <div className="input-control change-pw">
                            <input
                              type="password"
                              value={userpwToChange.userpwnew}
                              required
                              pattern="^(?=.*\d).{8,}$"
                              title="Min. 8 Zeichen und 1 Zahl."
                              onChange={(e) =>
                                setUserpwToChange({
                                  ...userpwToChange,
                                  userpwnew: e.target.value,
                                })
                              }
                            />
                          </div>

                          <div className="label-box">
                            <p
                              className="label"
                              style={{ marginBottom: "2rem" }}
                            >
                              Passwort bestätigen
                            </p>
                          </div>
                          <div
                            className="input-control change-pw"
                            style={{ marginBottom: "2rem" }}
                          >
                            <input
                              type="password"
                              value={userpwToChange.userpwnewconf}
                              required
                              pattern="^(?=.*\d).{8,}$"
                              title="Min. 8 Zeichen und 1 Zahl."
                              onChange={(e) =>
                                setUserpwToChange({
                                  ...userpwToChange,
                                  userpwnewconf: e.target.value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </form>
              </div>
            </>
          )}

          {isAdmin && (
            <div className="create-user">
              <div className="top-banner">
                <MyButton
                  text="Neuer Benutzer"
                  onClick={() => setAddUserVisible(!addUserVisible)}
                  renderIcon={() => {
                    if (addUserVisible)
                      return (
                        <FaAngleDown
                          size={30}
                          style={{ margin: "0 2rem 0 0.5rem" }}
                        />
                      );
                    else
                      return (
                        <FaAngleRight
                          size={30}
                          style={{ margin: "0 2rem 0 0.5rem" }}
                        />
                      );
                  }}
                  customClass="banner-button"
                />
              </div>

              <AnimatePresence initial={false}>
                {addUserVisible && (
                  <motion.div
                    className="add-user-container"
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: "auto" },
                      collapsed: { height: 0 },
                    }}
                  >
                    <form id="add-user-form" onSubmit={onCreateNewUser}>
                      <div className="input-control add-user">
                        <label>Benutzername</label>
                        <input
                          type="text"
                          value={newUser.username}
                          required
                          onChange={(e) =>
                            setNewUser({ ...newUser, username: e.target.value })
                          }
                        />
                      </div>
                      <div className="input-control add-user">
                        <label>Passwort</label>
                        <input
                          type="password"
                          value={newUser.userpw}
                          required
                          pattern="^(?=.*\d).{8,}$"
                          title="Min. 8 Zeichen und 1 Zahl."
                          onChange={(e) =>
                            setNewUser({ ...newUser, userpw: e.target.value })
                          }
                        />
                      </div>
                      <MyButton
                        text="Erstellen"
                        onClick={() => {}}
                        form="add-user-form"
                      />
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserManager;
