import MyButton from './MyButton';

// Images
import { ReactComponent as Logo } from '../svg/juv_logo.svg';

const MyHeader = ({ onLogoutClick }) => {
  return (
    <header>
      <div className='header-field-logo'>
        <Logo title='Juventus Schulen' 
          width='340'
          height='80'
        />
      </div>

      <div className='header-field-title'>
        <div className='title-text'>
          <h1 style={{fontSize: '32pt', margin: 0}}>Infopanel Admin</h1>
        </div>
      </div>
      
      <div className='header-field-button'>
        <MyButton text='Logout' onClick={onLogoutClick}/>
      </div>
    </header>
  )
}

export default MyHeader;
