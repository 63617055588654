import { useState } from 'react';

// Components
import MyButton from './MyButton';
import ConfirmPopUp from './ConfirmPopUp';

import { deps, buildings, rooms_102, rooms_45 } from '../config';

const AddEvent = ({ eventToEdit, onCancelAdd, addEvent }) => {
  const [ editedEvent, setEditedEvent ] = useState({
    id: '',
    fromDatetime: '',
    toDatetime: '',
    department: '',
    className: '',
    subject: '',
    teacher: '',
    building: '',
    room: '',
    modifiedAt: '',
    modifiedBy: '',
    visible: true,
  });
  const [ datetime, setDatetime ] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
  })
  const [ confirmVisible, setConfirmVisible ] = useState(false);

  const onConfirm = () => {
    eventToEdit.current = editedEvent;
    eventToEdit.current = {...eventToEdit.current, 
      fromDatetime: datetime.fromDate + ' ' + datetime.fromTime,  
      toDatetime: datetime.toDate + ' ' + datetime.toTime,  
    }

    addEvent();
    setConfirmVisible(false);
  }

  const onCancel = () => {
    setConfirmVisible(false);
  }

  const submit = (e) => {
    e.preventDefault();

    setConfirmVisible(true);
  }

  return (
    <>
      { confirmVisible && (
        <>
          <div className='background confirm'>
          </div>
          <ConfirmPopUp
            message='Soll das Ereignis hinzugefügt werden?'
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        </>
      ) }

      <div className='hover-box edit-event-container'>
        <form id='edit-event-form' onSubmit={submit}>
          <div className='input-control from-field'>
            <div>
              <label>Von</label>
            </div>
            <input type='date' value={datetime.fromDate}
              required
              min='2000-01-01' max='2030-01-01'
              onChange={(e) => setDatetime({ ...datetime, fromDate: e.target.value })} 
            />
          </div>

          <div className='input-control from-time-field'>
            <input type='time' value={datetime.fromTime}
              required
              onChange={(e) => setDatetime({ ...datetime, fromTime: e.target.value })} 
            />
          </div>

          <div className='input-control to-field'>
            <div>
              <label>Bis</label>
            </div>
            <input type='date' value={datetime.toDate}
              required
              min={datetime.fromDate} max='2030-01-01'
              onChange={(e) => setDatetime({ ...datetime, toDate: e.target.value })} 
            />
          </div>

          <div className='input-control to-time-field'>
            <input type='time' value={datetime.toTime}
              required
              onChange={(e) => setDatetime({ ...datetime, toTime: e.target.value })} 
            />
          </div>

          <div className='input-control department-field'>
            <div>
              <label>Bereich</label>
            </div>
            <select 
              value={editedEvent.department}
              required
              onChange={(e) => setEditedEvent({...editedEvent, department: e.target.value})}>
              {
                deps.map((dep) => <option 
                  key={dep} 
                  value={dep}>{dep}
                </option> )
              }
            </select>
          </div>

          <div className='input-control building-field'>
            <div>
              <label>Gebäude</label>
            </div>
            <select
              value={editedEvent.building}
              onChange={(e) => setEditedEvent({...editedEvent, building: e.target.value})}>
              {
                buildings.map((building) => <option 
                  key={building} 
                  value={building}>{building}
                </option> )
              }
            </select>
          </div>

          <div className='input-control room-field'>
            <div>
              <label>Raum</label>
            </div>
            {
              (editedEvent.building === 'Lagerstrasse 45') ?
                <select value={editedEvent.room}
                  required
                  onChange={(e) => setEditedEvent({...editedEvent, room: e.target.value})} >
                  {
                    rooms_45.map((room) => <option 
                      key={room}
                      value={room}>{room}
                    </option> )
                  }
                </select> : (editedEvent.building === 'Lagerstrasse 102') ?

                  <select value={editedEvent.room}
                    required
                    onChange={(e) => setEditedEvent({...editedEvent, room: e.target.value})} >
                    {
                      rooms_102.map((room, key) => <option 
                        key={key}
                        value={room}>{room}
                      </option> )
                    }
                  </select> : 

                    <select value={editedEvent.room}
                      required
                      onChange={(e) => setEditedEvent({...editedEvent, room: e.target.value})} >
                      <option value=''></option>
                      <option value='Online'>Online</option>
                    </select>
            }
          </div>

          <div className='input-control class-field'>
            <div>
              <label>Klasse</label>
            </div>
            <input type='text' value={editedEvent.className}
              onChange={(e) => setEditedEvent({...editedEvent, className: e.target.value})} 
            />
          </div>

          <div className='input-control teacher-field'>
            <div>
              <label>Dozent</label>
            </div>
            <input type='text' value={editedEvent.teacher}
              onChange={(e) => setEditedEvent({...editedEvent, teacher: e.target.value})} 
            />
          </div>

          <div className='input-control subject-field'>
            <div>
              <label>Fach</label>
            </div>
            <input type='text' value={editedEvent.subject}
              required
              onChange={(e) => setEditedEvent({...editedEvent, subject: e.target.value})} 
            />
          </div>
        </form>

        <div className='buttons'>
          <MyButton
            text='Abbrechen'
            onClick={onCancelAdd}
          />
          <MyButton
            text='Hinzufügen'
            onClick={() => {}}
            form='edit-event-form'
          />
        </div>
      </div>
    </>
  )
}

export default AddEvent;
